
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{
  font-family: 'TerminaMedium';
  src: local('TerminaMedium'), url(./assets/fonts/Termina-Medium.otf) format('OpenType');
}

@font-face{
  font-family: 'TerminaDemi';
  src: local('TerminaDemi'), url(./assets/fonts/Termina-Demi.otf) format('OpenType');
}

@font-face{
  font-family: 'TerminaLight';
  src: local('TerminaLight'), url(./assets/fonts/Termina-Light.otf) format('OpenType');
}

@font-face{
  font-family: 'TerminaBold';
  src: local('TerminaBold'), url(./assets/fonts/Termina-Bold.otf) format('OpenType');
}

@font-face{
  font-family: 'InterMedium';
  src: local('InterMedium'), url(./assets/fonts/Inter-Medium.ttf) format('truetype');
}

@font-face{
  font-family: 'InterRegular';
  src: local('InterRegular'), url(./assets/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face{
  font-family: 'InterLight';
  src: local('InterLight'), url(./assets/fonts/Inter-Light.ttf) format('truetype');
}

