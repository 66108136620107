.loading-container{
    height: 100vh;
    background-color:#BE3A27;
    h1{
        letter-spacing: 0px;
        color: white;
        opacity: 1;
        font-size: 40px;
        font-family: 'TerminaMedium';
        letter-spacing: 4px;
    }
    img{
        width: 400px;

        @media (max-width:450px){
            width: 290px;
        }
    }
}